import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteJSON, fetchJSON, patchJSON, postJSON } from 'api/fetch';

import { TASK_MANAGER_SLICE_NAME } from 'features/taskManager/constants';
import {
  HistoryTasksResponse,
  OnFetchTaskProps,
  PaginatedTaskResult,
  SingleTask,
  TaskList,
} from 'features/taskManager/index.interface';
import { getManagerTasksUrl } from 'features/taskManager/util';

export const createTask = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/createTask`,
  async (data: SingleTask): Promise<SingleTask> => {
    try {
      return await postJSON('/task_management/tasks', data);
    } catch (err: any) {
      const error = await err.response.json();
      throw new Error(error.errors[0]?.message || error.errors[0]);
    }
  }
);

export const updateSingleTask = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/updateSingleTask`,
  async (data: SingleTask): Promise<SingleTask> => {
    try {
      return await patchJSON(`/task_management/tasks/${data.id}`, data);
    } catch (err: any) {
      const error = await err.response.json();
      throw new Error(error.errors[0]?.message || error.errors[0]);
    }
  }
);

export const fetchTasks = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchTasks`,
  async ({
    perPage = 20,
    pageNumber = 1,
    locationId,
  }: OnFetchTaskProps): Promise<PaginatedTaskResult> =>
    fetchJSON(getManagerTasksUrl({ pageNumber, perPage, locationId }))
);

export const deleteSingleTask = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/deleteSingleTask`,
  async (id: string): Promise<number> => {
    await deleteJSON(`/task_management/tasks/${id}`);
    return Number(id);
  }
);

export const createTaskList = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/createTaskList`,
  async (data: TaskList) => {
    try {
      return await postJSON('/task_management/lists', data);
    } catch (err: any) {
      const error = await err.response.json();
      throw new Error(error.errors[0]?.message || error.errors[0]);
    }
  }
);

export const updateTaskList = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/updateTaskList`,
  async (data: TaskList) => {
    try {
      return await patchJSON(`/task_management/lists/${data.id}`, data);
    } catch (err: any) {
      const error = await err.response.json();
      throw new Error(error.errors[0]?.message || error.errors[0]);
    }
  }
);

export const deleteTaskList = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/deleteTaskList`,
  async (id: number) => {
    await deleteJSON(`/task_management/lists/${id}`);
    return id;
  }
);

export const fetchDepartments = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchDepartments`,
  async (locationId: number) =>
    fetchJSON(`/locations/${locationId}/departments`)
);

export const fetchRoles = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchRoles`,
  async (locationId: number) =>
    fetchJSON(`/locations/${locationId}/roles/search`)
);

export const fetchEmployees = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchEmployees`,
  async (locationId: number) => fetchJSON(`/locations/${locationId}/team`)
);

export const getTaskById = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/getTaskById`,
  async (id: number) => fetchJSON(`/task_management/lists/${id}`)
);

export const fetchHistoryTasks = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchHistoryTasks`,
  ({
    date,
    locationId,
    limit = 100,
  }: {
    date: number;
    limit?: number;
    locationId: number;
  }): Promise<HistoryTasksResponse> =>
    fetchJSON(
      `/task_management/tasks?date_in_secs=${date}&location_id=${locationId}&limit=${limit}`
    )
);

export const fetchTodayTasks = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchTodayTasks`,
  ({
    date,
    locationId,
    limit = 100,
  }: {
    date: number;
    limit?: number;
    locationId: number;
  }): Promise<HistoryTasksResponse> =>
    fetchJSON(
      `/task_management/tasks?date_in_secs=${date}&location_id=${locationId}&limit=${limit}`
    )
);
