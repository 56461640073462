import { XYCoord } from 'react-dnd';

import {
  I18N_ERRORS_PATH,
  SINGLE_TASK_FORM_I18N_PATH,
  TASK_MANAGER_DATE_FORMAT,
} from 'features/taskManager/constants';

import {
  datePickerFormat,
  df,
  fromUnix,
  momentDate,
  STANDARD_DATE_FORMAT,
  today,
} from 'util/dateTime';
import { toI18n } from 'util/i18n';

import {
  AssignTo,
  InitialFormValues,
  OccurrencePattern,
  OnFetchTaskProps,
  SingleTask,
  TableTask,
  TaskList,
} from './index.interface';

const getGenericInitialValues = (
  task?: SingleTask | TaskList
): {
  dueDate: string;
  dueTime: string;
  startDate: string;
  startTime: string;
  repeatOn: number[];
} => {
  let dueDate = '';
  let dueTime = '10:00 am';
  let startDate = '';
  let startTime = '8:00 am';
  const repeatOn: number[] = [];

  if (task?.end_at_secs) {
    dueDate = fromUnix(task.end_at_secs, STANDARD_DATE_FORMAT);
    dueTime = fromUnix(task.end_at_secs, df('time_long'));
  }

  if (task?.start_at_secs) {
    startDate = fromUnix(task.start_at_secs, STANDARD_DATE_FORMAT);
    startTime = fromUnix(task.start_at_secs, df('time_long'));
  }

  if (task?.occurrence_day) repeatOn.push(task.occurrence_day);

  return { dueDate, dueTime, startDate, startTime, repeatOn };
};

export const getInitialValues = (task?: TaskList) => {
  const { dueTime, dueDate, repeatOn, startTime, startDate } =
    getGenericInitialValues(task);

  return {
    id: task?.id,
    dueTime,
    repeatOn,
    startTime,
    name: task?.name || '',
    description: task?.description || '',
    dueDate: dueDate ? datePickerFormat(dueDate) : '',
    occurrence_pattern: task?.occurrence_pattern || '',
    startDate: startDate ? datePickerFormat(startDate) : '',
    tasks:
      task?.tasks?.map(subtask => ({
        id: subtask.id,
        name: subtask.name,
        description: subtask.description || '',
        assigneeType: subtask.assignee_type,
        assigneeName: subtask.assignee_name,
        assigneeId: subtask.assignee_id,
      })) || [],
  };
};

const DEFAULT_FIELDS = [
  'name',
  'description',
  'occurrence_pattern',
  'assignee_type',
];

export const taskDateFormat = (time: string, date?: string) => {
  const defaultDate = date ?? today(STANDARD_DATE_FORMAT);
  const dateObj = momentDate(
    defaultDate + ' ' + time,
    TASK_MANAGER_DATE_FORMAT
  );
  return dateObj.unix();
};

export const getSingleTaskSubmitValue = (
  values: InitialFormValues,
  currentLocationId: number
) => {
  const submitValue = DEFAULT_FIELDS.reduce((memo, field) => {
    memo[field] = (values as any)[field];
    return memo;
  }, {} as { [key: string]: any });

  submitValue.assignee_id =
    values.assignee_type === 'Location'
      ? currentLocationId
      : values.deptRoleEmp;
  submitValue.location_id = currentLocationId;
  const doesNotRepeat = values.occurrence_pattern === 'does_not_repeat';

  submitValue.start_at_secs = taskDateFormat(
    values.startTime,
    doesNotRepeat ? values.startDate : undefined
  );
  submitValue.end_at_secs = taskDateFormat(
    values.dueTime,
    doesNotRepeat ? values.dueDate : undefined
  );

  if (values.occurrence_pattern === 'weekly') {
    submitValue.occurrence_day = values.repeatOn[0];
  }

  if (values.id) submitValue.id = values.id;
  return submitValue as SingleTask;
};

export const getSingleTaskInitialValues = (
  task?: SingleTask
): InitialFormValues => {
  const { dueTime, dueDate, repeatOn, startTime, startDate } =
    getGenericInitialValues(task);

  return {
    id: task?.id,
    dueTime,
    dueDate,
    repeatOn,
    startTime,
    startDate,
    name: task?.name || '',
    description: task?.description || '',
    assignee_type: task?.assignee_type || ('' as any),
    occurrence_pattern: task?.occurrence_pattern || '',
    deptRoleEmp: task?.assignee_id ?? '',
  } as InitialFormValues;
};

export const getTaskListSubmitValue = (
  values: any,
  currentLocationId?: number
) => {
  const doesNotRepeat = values.occurrence_pattern === 'does_not_repeat';

  const submitValue = {
    location_id: currentLocationId,
    name: values.name,
    description: values.description,
    occurrence_pattern: values.occurrence_pattern,
    end_at_secs: taskDateFormat(
      values.dueTime,
      doesNotRepeat ? values.dueDate : undefined
    ),
    start_at_secs: taskDateFormat(
      values.startTime,
      doesNotRepeat ? values.startDate : undefined
    ),
    occurrence_day:
      values.occurrence_pattern === 'weekly' ? values.repeatOn[0] : undefined,
    tasks: values.tasks.map((task: any) => ({
      _delete: Boolean(task._delete),
      id: /new_/.test(task.id) ? undefined : task.id,
      name: task.name,
      description: task.description,
      assignee_type: task.assigneeType,
      assignee_id: task.assigneeId,
    })),
  } as any;

  if (values.id) submitValue.id = values.id;
  return submitValue as TaskList;
};

export const getAssignToText = (assignTo: AssignTo) => {
  switch (assignTo) {
    case 'Organization::Department':
      return toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.department`);
    case 'User':
      return toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.employee`);
    case 'Organization::Role':
      return toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.role`);
    default:
      return toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.dept_role_emp`);
  }
};

export const getQueryParam = (queryString: string, paramName: string) => {
  const params = new URLSearchParams(queryString);
  return params.get(paramName) || undefined;
};

export const handleHover = (
  item: any,
  monitor: any,
  index: number,
  ref: any,
  moveRow: any
) => {
  if (!ref.current) return;

  const dragIndex = item.index;
  const hoverIndex = index;

  if (dragIndex === hoverIndex) return;

  const hoverBoundingRect = ref.current?.getBoundingClientRect();
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

  if (
    (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
    (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
  )
    return;

  moveRow?.(dragIndex, hoverIndex);
  item.index = hoverIndex;
};

export const getManagerTasksUrl = ({
  pageNumber,
  perPage,
  locationId,
}: OnFetchTaskProps) =>
  `/task_management/manager_tasks?page=${pageNumber}&per_page=${perPage}&location_id=${locationId}`;

export const historyDateFormat = (selectedDate: string) =>
  (momentDate(selectedDate, STANDARD_DATE_FORMAT) as any).utc('+0000').unix();

export const onUpdateTask = ({
  task,
  setCompletedTasks,
  setUncompletedTasks,
}: {
  task: TableTask;
  setCompletedTasks: (value: (value: TableTask[]) => TableTask[]) => void;
  setUncompletedTasks: (value: (value: TableTask[]) => TableTask[]) => void;
}) => {
  const removeItem = (currentTasks: TableTask[]) =>
    currentTasks.filter(currentTask => currentTask.id !== task.id);
  const addItem = (currentTasks: TableTask[]) => [
    ...removeItem(currentTasks),
    task,
  ];

  if (Boolean(task.completed_at_secs)) {
    // remove from incomplete
    setUncompletedTasks(removeItem);
    // add to complete
    setCompletedTasks(addItem);
  } else {
    // remove from complete
    setCompletedTasks(removeItem);
    // add to incomplete
    setUncompletedTasks(addItem);
  }
};

export const timesAndDatesAreValid = ({
  endAtSecs,
  startAtSecs,
  occurrencePattern,
  setFieldError,
}: {
  endAtSecs: number;
  startAtSecs: number;
  occurrencePattern?: OccurrencePattern;
  setFieldError: (field: string, message: string | undefined) => void;
}) => {
  if (endAtSecs <= startAtSecs) {
    const doesNotRepeat = occurrencePattern === 'does_not_repeat';

    if (doesNotRepeat) {
      const isSameDay =
        fromUnix(endAtSecs, STANDARD_DATE_FORMAT) ===
        fromUnix(startAtSecs, STANDARD_DATE_FORMAT);

      if (isSameDay) {
        setFieldError('dueTime', toI18n(`${I18N_ERRORS_PATH}.due_time_error`));
        return;
      }
    }

    const errorMessage = toI18n(
      `${I18N_ERRORS_PATH}.${
        doesNotRepeat ? 'due_date_error' : 'due_time_error'
      }`
    );

    setFieldError(doesNotRepeat ? 'dueDate' : 'dueTime', errorMessage);
    return;
  }

  return true;
};
